<template>
  <div>
    <a-modal
      title="添加推荐商品"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-row :gutter="24">
          <a-col :md="24" :sm="24">
            <a-form :form="form">
              <a-form-item label="商品" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="+bannerType === 1">
                <div
                  style="cursor: pointer; width: 100%; height: 100%; position: absolute; z-index: 99; left: 0; top: 0"
                  @click="$refs.chooseProduct.init()"
                ></div>
                <a-input
                  disabled
                  placeholder="请选择"
                  v-decorator="['objectName', { rules: [{ required: true, message: '请选择商品' }] }]"
                >
                  <span slot="suffix"> <a-icon type="right" /></span>
                </a-input>
                <a-input
                  style="visibility: hidden; position: absolute; top: 99999px"
                  placeholder="请选择"
                  v-decorator="['goodsId', { rules: [{ required: true, message: '请选择商品' }] }]"
                />
              </a-form-item>

              <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input-number
                  placeholder="请输入"
                  :min="1"
                  :precision="0"
                  :max="10000000"
                  style="width: 40%; min-width: 100px"
                  v-decorator="['sort', { rules: [{ required: true, message: '请输入排序！' }], initialValue: 10 }]"
                />
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>

    <choose-product ref="chooseProduct" :areaId="areaId" />
  </div>
</template>

<script>
import { AntdEditor } from '@/components'
import chooseProduct from './chooseProduct.vue'
import editForm from '@/views/system/bannerList/editForm.vue'
import { addRecommendGoods } from '@/api/modular/mallLiving/commodity/commodityList'

export default {
  components: { editForm, chooseProduct, AntdEditor },
  props: {
    areaId: {
      type: String,
    },
  },
  data() {
    this.lastFetchId = 0
    return {
      lastFetchId: 0,
      fetching: false,
      typeList: [
        {
          name: '商品',
          value: 1,
        },
        {
          name: '商品分类（二级分类）',
          value: 2,
        },
        {
          name: '富文本',
          value: 3,
        },
      ],
      loading: false,
      imageUrl: '',
      picture: '',
      animationUrl: '',
      allList: [],
      animationList: [],
      searchList: [],
      pictureLoading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelCol_1: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol_1: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      bannerType: '1', //1商品 2商品分类 3富文本
      editorContent: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    setProduct(product) {
      this.form.setFieldsValue({ objectName: product.objectName })
      this.form.setFieldsValue({ goodsId: product.objectId })
    },

    // 富文本内容变化
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    // 富文本初始化
    getEditor(editor) {
      this.editor = editor
    },

    beforeUpload(file) {
      this.animationList = [file]
      return true
    },
    // 初始化方法
    add() {
      let that = this
      //下面是正常的初始化方法
      this.visible = true
      this.picture = ''
    },
    previewFile(file) {
      console.log('Your upload file:', file)
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          addRecommendGoods({ ...values, areaId: this.areaId }).then((res) => {
            if (res.success) {
              this.$message.success('添加推荐商品成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.animationList = []
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
