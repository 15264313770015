<template>
  <a-modal
    title="选择商品"
    width="50%"
    :visible="visible"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <div class="right-content">
      <!-- 筛选 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="40">
            <a-col :md="6" :sm="24">
              <!-- 筛选 -->
              <a-form-item label="商品状态">
                <a-select v-model="queryParam.type" allow-clear placeholder="请选择">
                  <a-select-option :value="1">出售中</a-select-option>
                  <a-select-option :value="2">已售罄</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <!-- 商品分类 -->
              <a-form-item label="所属分类">
                <a-tree-select
                  v-model="queryParam.goodsCategoryId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-tree-select-node
                    v-for="parentItem in classList"
                    :key="parentItem.id"
                    :value="parentItem.id"
                    :title="parentItem.name"
                    :selectable="false"
                  >
                    <a-tree-select-node
                      v-for="item in parentItem.childrenList"
                      :key="item.id"
                      :selectable="true"
                      :value="item.id"
                      :title="item.name"
                    />
                  </a-tree-select-node>
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <!-- 商品搜索 -->
              <a-form-item label="商品搜索">
                <a-input v-model="queryParam.keywords" allow-clear placeholder="请输入商品名称、关键字" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-button type="primary" @click="searchTableData">搜索</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        rowKey="id"
        :row-selection="rowSelection"
      >
        <!-- 商品图片插槽 -->
        <span slot="commodityImg" slot-scope="text, record">
          <viewer :images="[record.coverImage]">
            <img
              :src="record.coverImage"
              style="width: 50px; height: 50px; border: 0.5px solid #ccc; padding: 5px; margin: 3px"
              alt=""
            />
          </viewer>
        </span>
        <!-- 规格类型插槽 -->
        <span slot="speType" slot-scope="text">
          {{ text == 1 ? '单规格' : text == 2 ? '多规格' : '' }}
        </span>
      </a-table>
      <!-- 分页组件 -->
      <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
        <a-pagination
          :pageSize="page.pageSize"
          :current="page.pageNo"
          show-size-changer
          :page-size-options="['10', '20', '50', '100']"
          show-quick-jumper
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="pageChange"
          @showSizeChange="sizeChange"
        ></a-pagination>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {
  commodityDelete,
  deleteGoodsSpec,
  deleteGoodsSpecValue,
  getCommodityList,
  platformLowerShelfGoods,
  querySpecListByCommodityId,
  querySpecValueListBySpcId,
} from '@/api/modular/mallLiving/commodity/commodityList'
import { findCategoryListByStore } from '@/api/modular/mallLiving/commodity/commodityClass'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
    width: '260px',
  },
  {
    title: '商品分类',
    dataIndex: 'goodsCategoryName',
    key: 'goodsCategoryName',
    align: 'center',
    width: '150px',
  },
  {
    title: '封面图',
    dataIndex: 'coverImage',
    key: 'coverImage',
    align: 'center',
    scopedSlots: {
      customRender: 'commodityImg',
    },
    width: '100px',
  },
  {
    title: '商品售价',
    key: 'price',
    dataIndex: 'price',
    align: 'center',
    width: '160px',
  },
  {
    title: '销售量',
    key: 'sales',
    dataIndex: 'sales',
    align: 'center',
    width: '160px',
  },
  {
    title: '规格类型',
    key: 'speType',
    dataIndex: 'speType',
    align: 'center',
    scopedSlots: {
      customRender: 'speType',
    },
    width: '160px',
  },
  {
    title: '库存',
    key: 'stock',
    dataIndex: 'stock',
    align: 'center',
    width: '86px',
  },
]
export default {
  name: 'chooseProduct',
  props: {
    areaId: {
      type: String,
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      visible: false,
      confirmLoading1: false,
      columns,
      currentItem: null,
      currentSpecItem: null,
      data: [],
      loading: false,
      confirmLoading2: false,
      visible2: false,
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      classList: [], //顶部商品分类筛选数
      queryParam: {
        commodityClass: undefined, //商品分类
        commoditySearch: undefined, //商品搜索
      },
      specPage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specValuePage: {
        pageNumber: 1,
        pageSize: 10,
      },
      specTotal: 0,
      specValueTotal: 0,
      specValueColumns: [
        {
          title: '规格属性名称',
          dataIndex: 'specValueName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specColumns: [
        {
          title: '规格名称',
          dataIndex: 'specName',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 200,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      specDatas: [],
      specValueDatas: [],
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        type: 'radio',
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },

  methods: {
    init() {
      this.visible = true
      this.initTableData()
      this.getCommodityClassList() //获取下拉列表分类信息
    },
    specPageChange(pageNo, pageSize) {
      this.specPage.pageNumber = pageNo
      this.specPage.pageSize = pageSize
      this.querySpecvalueListBySpecId()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    handleSubmit() {
      if (!this.selectedRows.length) {
        return this.$message.info('请选择商品！')
      }
      console.log(this.selectedRows)
      const { id, name } = this.selectedRows[0]
      this.$parent.setProduct({ objectName: name, objectId: id })
      this.handleCancel()
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
    },
    confirmDeleteSpecValue(record) {
      deleteGoodsSpecValue({
        id: record.goodsSpecValueId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格属性删除成功')
          this.querySpecvalueListBySpecId()
        }
      })
    },
    confirmDeleteSpec(record) {
      deleteGoodsSpec({
        id: record.goodsSpecId,
      }).then((res) => {
        if (res.success) {
          this.$message.success('商品规格删除成功')
          this.findCommoditySpecList()
        }
      })
    },
    showModal2(record) {
      this.currentSpecItem = record
      this.querySpecvalueListBySpecId()
      this.visible2 = true
    },

    handleCancel1() {
      this.visible1 = false
    },

    handleCancel2(e) {
      this.visible2 = false
    },

    async querySpecvalueListBySpecId() {
      const data = await querySpecValueListBySpcId({
        goodsSpecId: this.currentSpecItem.goodsSpecId,
      })
      if (data.code == 200) {
        this.specValueDatas = data.data
      }
    },
    handleSpecvalueOk() {
      this.specValuePage.pageNumber = 1
      this.querySpecvalueListBySpecId()
    },
    handleSpecOk() {
      this.specPage.pageNumber = 1
      this.findCommoditySpecList()
    },

    async findCommoditySpecList() {
      var params = {
        page: this.specPage,
        queryParam: this.currentItem.id,
      }
      const data = await querySpecListByCommodityId({
        goodsId: this.currentItem.id,
      })
      if (data.code == 200) {
        this.specDatas = data.data
      }
    },
    // 获取商品分类及其子列表信息
    getCommodityClassList() {
      findCategoryListByStore().then((res) => {
        //处理分类数据
        this.classList = res.data
      })
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    searchTableData() {
      this.initTableData()
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: { ...this.queryParam, areaId: this.areaId },
      }
      getCommodityList(params)
        .then((res) => {
          if (res.success) {
            this.loading = false
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
              item.banned = +item.isLowerShelf !== 1
            })
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    changeBanned(event, record) {
      let content = event ? '确定下架当前商品？' : '确定取消下架当前商品？'
      let msgContent = event ? '下架当前商品成功' : '取消下架当前商品成功'
      let errorContent = event ? '下架当前商品失败' : '取消下架当前商品失败'
      this.$confirm({
        title: '提示',
        content,
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          console.log('OK')
          platformLowerShelfGoods({ goodsId: record.id, isLowerShelf: event ? 2 : 1 })
            .then((res) => {
              if (res.success) {
                this.$message.success(msgContent)
                this.getList()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || errorContent)
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await commodityDelete(params)
      var text = delFlag == 1 ? '上架' : delFlag == 2 ? '删除' : '下架'
      if (data.code == 200) {
        this.$message.success(this.recordItem.name + ' 已' + text)
        this.getList()
      }
    },
    //添加商品页面路由跳转
    addCommodity() {
      this.$router.push({
        path: '/addCommodity',
      })
    },
  },
}
</script>

<style scoped>
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
